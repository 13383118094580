import { urlsWrapper } from 'config';
import { serialize } from 'utils';
import { getTracksByWorkrecords } from 'modules/workRecords';
import { commonGet } from './api';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';

const constants = createCommonListConstants('GLOBAL_WORKRECORDS');

// reducer
export default createCommonListReducer(constants);

// actions
export function getGlobalWorkrecords(params) {
  return saveData(
    `${urlsWrapper('workrecords')}?${serialize(params)}`,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

export const getWorkrecordsWithTracks = (params) => async (dispatch) => {
  const workrecords = await commonGet({
    url: `${urlsWrapper('workrecords')}?${serialize(params)}`,
  });
  return dispatch(getTracksByWorkrecords(workrecords?.data, constants));
};
