import moment, { utc } from 'moment';
import {
  getCurrentCustomer,
  getCurrentAdvisor,
  getCurrentUser,
} from 'modules/auth';
import contentDisposition from 'content-disposition';
import { baseUrl } from 'config';

function updateAccessTokenTimestamp() {
  localStorage.setItem('JWT_ACCESS_LAST_TIMESTAMP', new Date().getTime());
}

function updateRefreshTokenTimestamp() {
  localStorage.setItem('JWT_REFRESH_LAST_TIMESTAMP', new Date().getTime());
}

export function parseToken(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(Buffer.from(base64, 'base64').toString());
}

export const storeTokens = async (tokens, dispatch) => {
  localStorage.setItem('JWT_ACCESS', tokens.access_token);
  localStorage.setItem('JWT_REFRESH', tokens.refresh_token);
  localStorage.setItem(
    'JWT_ACCESS_TOKEN_LIFE_TIME',
    tokens.access_token_lifetime
  );
  localStorage.setItem(
    'JWT_REFRESH_TOKEN_LIFE_TIME',
    tokens.refresh_token_lifetime
  );
  updateAccessTokenTimestamp();
  updateRefreshTokenTimestamp();

  const { user_claims } = parseToken(tokens.access_token);
  // TODO: think about user reducer in store
  localStorage.setItem('USER_ROLE', user_claims.admin ? 'admin' : 'user');
  localStorage.setItem('USER_ID', user_claims.user_id);

  if (user_claims.customer_id) {
    localStorage.setItem('CUSTOMER_ID', user_claims.customer_id);
    const customer = await dispatch(
      getCurrentCustomer(user_claims.customer_id)
    );
    localStorage.setItem('AUTH_USER_COUNTRY', customer?.country);
  }

  if (user_claims.advisor_id) {
    localStorage.setItem('ADVISOR_ID', user_claims.advisor_id);
    const advisor = await dispatch(getCurrentAdvisor(user_claims.advisor_id));
    localStorage.setItem('AUTH_USER_COUNTRY', advisor?.country);
  }

  dispatch(getCurrentUser(user_claims.user_id));
};

export function isAdmin() {
  const role = localStorage.getItem('USER_ROLE');
  return !!(role && role == 'admin');
}

export function getUserId() {
  return localStorage.getItem('USER_ID');
}

export function getCustomerId() {
  return localStorage.getItem('CUSTOMER_ID');
}

export function getAdvisorId() {
  return localStorage.getItem('ADVISOR_ID');
}

export function setAdvisorCustomer(customer) {
  sessionStorage.setItem('ADVISOR_CUSTOMER_ID', customer.id);
  sessionStorage.setItem('ADVISOR_CUSTOMER_NAME', customer.name);
}

export function getAdvisorCustomerId() {
  return sessionStorage.getItem('ADVISOR_CUSTOMER_ID');
}

export function getCustomerPath() {
  return `/customer/${getAdvisorCustomerId()}`;
}

export function getAdvisorCustomerName() {
  return sessionStorage.getItem('ADVISOR_CUSTOMER_NAME');
}

export const updateAccessToken = (tokens) => {
  localStorage.setItem('JWT_ACCESS', tokens.access_token);
  localStorage.setItem(
    'JWT_ACCESS_TOKEN_LIFE_TIME',
    tokens.access_token_lifetime
  );
  updateAccessTokenTimestamp();
};

export function isAccessTokenExpired() {
  const lastTokenTimestamp = localStorage.getItem('JWT_ACCESS_LAST_TIMESTAMP');

  const token = localStorage.getItem('JWT_ACCESS');

  const tokenLifeTime = localStorage.getItem('JWT_ACCESS_TOKEN_LIFE_TIME');

  const timestamp = new Date().getTime();
  return token && lastTokenTimestamp && tokenLifeTime
    ? +lastTokenTimestamp + tokenLifeTime * 1000 < timestamp
    : true;
}

export function isSessionExpired() {
  const lastTokenTimestamp = localStorage.getItem('JWT_REFRESH_LAST_TIMESTAMP');

  const tokenLifeTime = localStorage.getItem('JWT_REFRESH_TOKEN_LIFE_TIME');

  const token = localStorage.getItem('JWT_REFRESH');

  const timestamp = new Date().getTime();
  return token && lastTokenTimestamp && tokenLifeTime
    ? +lastTokenTimestamp + tokenLifeTime * 1000 < timestamp
    : true;
}

export function isSession() {
  return !(isAccessTokenExpired() || isSessionExpired());
}

export function cleanSessionStorage() {
  sessionStorage.removeItem('ADVISOR_CUSTOMER_ID');
  sessionStorage.removeItem('ADVISOR_CUSTOMER_NAME');
}

export function cleanLocalStorage() {
  localStorage.removeItem('JWT_ACCESS');
  localStorage.removeItem('JWT_ACCESS_LAST_TIMESTAMP');
  localStorage.removeItem('JWT_ACCESS_TOKEN_LIFE_TIME');
  localStorage.removeItem('JWT_REFRESH');
  localStorage.removeItem('JWT_REFRESH_LAST_TIMESTAMP');
  localStorage.removeItem('JWT_REFRESH_TOKEN_LIFE_TIME');
  localStorage.removeItem('USER_DIVISION_IDS');
  localStorage.removeItem('CUSTOMER_ID');
  localStorage.removeItem('USER_ID');
  localStorage.removeItem('USER_ROLE');
  localStorage.removeItem('ADVISOR_ID');
  localStorage.removeItem('SEASON');
  localStorage.removeItem('AUTH_USER_COUNTRY');
}

export const getIsSessionExpiredWithReset = () => {
  const isExpired = isSessionExpired();
  if (isExpired) {
    cleanLocalStorage();
  }
  return isExpired;
};

export function serialize(obj, prefix) {
  if (!obj) return '';
  const str = [];
  Object.keys(obj).forEach((p) => {
    const k = prefix ? `${prefix}[${p}]` : p;
    const v = obj[p];
    str.push(
      v !== null && typeof v === 'object'
        ? serialize(v, k)
        : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
    );
  });
  return str.join('&');
}

export function prepareError(message, _error, err = {}) {
  const errorObject = {
    _error,
    err,
  };
  if (typeof message === 'object' && message !== null) {
    Object.keys(message).forEach((key) => {
      errorObject[key] = message[key];
    });
  } else {
    errorObject._error += message ? ` ${message}` : '';
  }
  return errorObject;
}

export function formatDistance(distance) {
  if (!distance) {
    return '0 м';
  }

  const km = Math.floor(distance / 1000);
  const m = (distance - km * 1000).toFixed();
  if (km) {
    return `${km} км ${m} м`;
  }

  return `${m} м `;
}

export function checkFilter(modelId, filterId) {
  return !(filterId != null && filterId != '' && modelId != filterId);
}

export const getIsGlobalFilterDivisionsSelected = (globalFilterDivisions) =>
  globalFilterDivisions && globalFilterDivisions.indexOf(0) === -1;

export function checkGlobalDivisionsFilter(divisionId, globalFilter) {
  return getIsGlobalFilterDivisionsSelected(globalFilter)
    ? globalFilter.indexOf(divisionId) > -1
    : true;
}

export function removeNull(fields = {}, except = []) {
  const filteredFields = {};
  Object.keys(fields).forEach((key) => {
    const keyIndex = except.indexOf(key);
    if (keyIndex != -1 || fields[key] !== null) {
      filteredFields[key] = fields[key];
    }
  });

  return filteredFields;
}

export const isEmailValid = (value) =>
  !!value.match(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

const nextYears = moment(new Date()).add(4, 'year').year();
export const getYears = (yearsNumber = 12, lastYear = nextYears) => {
  const years = [];
  for (let i = lastYear; i > lastYear - yearsNumber; i -= 1) {
    years.push(i);
  }
  return years;
};

export const plural = (n, titles) =>
  titles[
    n % 10 === 1 && n % 100 !== 11
      ? 0
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? 1
      : 2
  ];

export const declOfNum = (n, titles) => `${Math.abs(n)} ${plural(n, titles)}`;

export const getItemById = (itemList, itemId) => {
  if (!itemList) {
    return null;
  }
  const itemIndex = itemList.findIndex((item) => itemId == item.id);
  return itemIndex != -1 ? itemList[itemIndex] : null;
};

export const camelize = (str) =>
  str
    .replace(/_/g, ' ')
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
      index == 0 ? letter.toLowerCase() : letter.toUpperCase()
    )
    .replace(/\s+/g, '');

export const getUserName = (user = {}) => {
  const userName = [];
  if (user.last_name) {
    userName.push(user.last_name);
  }
  if (user.first_name) {
    userName.push(user.first_name);
  }
  return userName.join(' ');
};

export const getBreedWithVendor = (
  seeds,
  breed,
  seedVendors,
  crop_id,
  additionalText
) => {
  const seed = seeds.find((s) =>
    crop_id ? s.crop_id === crop_id && s.name === breed : s.name === breed
  );
  const seedVendor = seedVendors.find(
    (s) => s.id === (seed || {}).seed_vendor_id
  );
  const vendorName =
    (seedVendor || {}).normalized_name || (seedVendor || {}).name;
  return vendorName
    ? `(${
        vendorName.length > 15 ? `${vendorName.slice(0, 15)}...` : vendorName
      }${additionalText || ''})`
    : '';
};

export const sortListByImportantAndName = (list = [], key = '') =>
  [...list].sort((aDirty, bDirty) => {
    const a = key ? aDirty[key] ?? {} : aDirty;
    const b = key ? bDirty[key] ?? {} : bDirty;
    return (
      b.important - a.important || (a.name || '').localeCompare(b.name || '')
    );
  });

export const convertEmptyValuesToNull = (object) =>
  Object.keys(object).reduce((acc, key) => {
    acc[key] = object[key] === '' ? null : object[key];
    return acc;
  }, {});

export const getUserInfo = (user, advisors = []) => {
  let userName = '';
  if (user) {
    userName = `${user.last_name} ${user.first_name}`;
    const advisor = advisors.find((a) => a.id === user.advisor_id);
    if (advisor) {
      userName += ` (${advisor.name})`;
    }
  }
  return userName;
};

export const getContentDisposition = (headers) => {
  // If Content-Disposition passed, use filename from it instead of default
  const dispositionHeader = headers['content-disposition'];
  if (dispositionHeader) {
    const disposition = contentDisposition.parse(dispositionHeader);
    return ((disposition || {}).parameters || {}).filename;
  }
  return '';
};

export const getTimeByFieldTimezone = (date, timezone = 0) =>
  utc(new Date(date)).utcOffset(timezone * 60);

export const getDateWithOffset = (date, fieldTzOffset) =>
  typeof fieldTzOffset === 'number'
    ? getTimeByFieldTimezone(date, fieldTzOffset)
    : moment(new Date(date));

export const getSowingSummary = (sowing, fieldTzOffset) => {
  if (!sowing) {
    return '';
  }

  if (sowing.plots_count) {
    return `${sowing.plots_count} ${plural(sowing.plots_count, [
      'участок',
      'участка',
      'участков',
    ])}`;
  }

  const summary = [];

  if (sowing.breed) {
    summary.push(sowing.breed);
  }
  if (sowing.sowed_at || sowing.harvested_at) {
    const sowed = sowing.sowed_at
      ? getDateWithOffset(sowing.sowed_at, fieldTzOffset).format('D MMM')
      : '?';
    const harvested = sowing.harvested_at
      ? getDateWithOffset(sowing.harvested_at, fieldTzOffset).format('D MMM')
      : '?';
    summary.push(`${sowed} - ${harvested}`);
  }
  if (sowing.field_yield) {
    summary.push(`${sowing.field_yield} ц/га`);
  }
  return summary.join(' | ');
};

export const COMMERCIAL_TYPE_NAME = 'TRIAL_COMMERCIAL_BASELINE';

export const isValidUrl = (string) => {
  try {
    // https://stackoverflow.com/a/43467144/1018686
    // eslint-disable-next-line no-new
    new URL(string);
  } catch (_) {
    return false;
  }

  return true;
};

export const getFileUrl = (path) =>
  isValidUrl(path) ? path : `${baseUrl}/${path}`;

export const composeFileUrl = (path) =>
  isValidUrl(path) ? path : `${baseUrl}${path}`;

export const formatDateBySeason = (date, season, fieldTzOffset = 0) => {
  if (!date) {
    return '';
  }
  const momentDate = getDateWithOffset(date, fieldTzOffset);
  const year = momentDate.format('YYYY');
  return momentDate.format(
    `D MMMM${parseInt(year, 10) !== parseInt(season, 10) ? ' YYYY' : ''}`
  );
};

export const paramsDateFormat = 'YYYY-MM-DD';

export const formatDateByParams = (date, params = paramsDateFormat) =>
  moment(date).format(params);

const getStartEndDateArray = (startDate, endDate) => {
  if (startDate && endDate) {
    if (startDate === endDate) {
      return [startDate];
    }
    return [startDate, endDate];
  }
  if (startDate && !endDate) {
    return [startDate];
  }
  if (!startDate && endDate) {
    return [endDate];
  }
  return null;
};

export const getStartEndDate = (startDate, endDate, isStringResult = true) => {
  const result = getStartEndDateArray(startDate, endDate);
  return isStringResult ? result?.join(' – ') ?? '' : result;
};

export const sortStartEndDate = (a, b) => {
  if (a.start_ts && b.start_ts) {
    return a.start_ts.localeCompare(b.start_ts);
  }
  if (a.start_ts && !b.start_ts) {
    return -1;
  }
  if (!a.start_ts && b.start_ts) {
    return 1;
  }
  return 0;
};

export const sortByDateKey = (arrayOfObjects, dateKey, direction = 'asc') =>
  arrayOfObjects.sort((a, b) =>
    direction === 'asc'
      ? new Date(a[dateKey]) - new Date(b[dateKey])
      : new Date(b[dateKey]) - new Date(a[dateKey])
  );

export const notSendedUpdateFieldNullKey = [
  'crop_id',
  'name',
  'description',
  'district_id',
  'geojson',
  'region_id',
  'ts_loaded',
  'tz_offset',
  'lat',
  'lon',
];

export const compose = (...fns) => (x) => fns.reduceRight((v, f) => f(v), x);

export const capitalize = (str) =>
  str?.length ? str.charAt(0).toUpperCase() + (str.slice(1) || '') : '';

export const getOriginalError = (e) => e?.errors?.err?.originalErrorData;
