import { urlsWrapper } from 'config';
import { createSelector } from 'reselect';
import { serialize, sortListByImportantAndName } from 'utils';
import { commonDelete, commonGet, commonPost, commonPut } from './api';
import {
  createCommonListConstants,
  createCommonListReducer,
  saveData,
} from './utils';
import { getTracksByWorkrecords } from './workRecords';

const constants = createCommonListConstants('EQUIPMENT_TYPE');
const constantsEquipmentsWorkrecords = createCommonListConstants(
  'EQUIPMENT_WORKRECORDS'
);
const equipments = createCommonListConstants('EQUIPMENTS');

export const equipmentType = createCommonListReducer(
  constants,
  sortListByImportantAndName
);

export default createCommonListReducer(equipments);

export function getEquipments() {
  return saveData(
    `${urlsWrapper('equipments')}`,
    equipments.SET_LIST,
    equipments.SET_IS_PENDING
  );
}

export const getEquipment = (id) =>
  commonGet({
    url: `${urlsWrapper('equipments')}/${id}`,
  });

const shouldFetchEquipments = (state) => !state.equipments.list.length;

export const fetchEquipmentsIfNeeded = () => (dispatch, getState) => {
  const state = getState();
  return shouldFetchEquipments(state)
    ? dispatch(getEquipments())
    : Promise.resolve();
};

export const deleteEquipments = (id) =>
  commonDelete({
    url: `${urlsWrapper('equipments')}/${id}`,
  });

export const updateEquipments = (data, id) =>
  commonPut({
    url: `${urlsWrapper('equipments')}/${id}`,
    data,
  });

const shouldFetchEquipmentType = (state) =>
  state.equipmentType.list.length === 0;

export function getEquipmentType() {
  return saveData(
    `${urlsWrapper('equipmentTypes')}`,
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

export const addEquipment = (data) =>
  commonPost({
    url: `${urlsWrapper('equipments')}`,
    data,
  });

export const fetchEquipmentTypesIfNeeded = () => (dispatch, getState) =>
  shouldFetchEquipmentType(getState())
    ? dispatch(getEquipmentType())
    : Promise.resolve();

export const getEqupmentTypeSelector = createSelector(
  [
    (state) => state.equipmentType.list,
    (state) => state.equipmentType.isPending,
  ],
  (list, isPending) => ({
    list,
    isPending,
  })
);

export const getEquipmentsSelector = createSelector(
  [(state) => state.equipments.list, (state) => state.equipments.isPending],
  (list, isPending) => ({
    list,
    isPending,
  })
);

export const getEquipmentName = (props) => {
  const { equipment, equipmentTypes, lower = false, mode = 'first' } =
    props || {};
  if (!equipment) return '';
  const { name, number } = equipment || {};
  let typeName = '';
  if (Array.isArray(equipmentTypes) && equipmentTypes.length) {
    typeName = equipmentTypes.find(
      (element) => element.id === equipment.equipment_type_id
    )?.name;
    typeName = typeName && lower ? typeName.toLowerCase() : typeName;
  }
  if (mode === 'first') {
    return [typeName, name, number].filter((v) => v).join(' ');
  }
  return [[name, number].filter((v) => v).join(' '), typeName]
    .filter((v) => v)
    .join(', ');
};

export const getEquipmentsWorkrecordsWithTracks = (machineId, params) => async (
  dispatch
) => {
  const workrecords = await commonGet({
    url: `${urlsWrapper('equipments')}/${machineId}/workrecords?${serialize(
      params
    )}`,
  });
  return dispatch(
    getTracksByWorkrecords(workrecords?.data, constantsEquipmentsWorkrecords)
  );
};

export const errorCodes = {
  WorkrecordMachineryExists: 'WorkrecordMachineryExists',
};

export const getErrorMessage = (e) => {
  if (e?.code === errorCodes.WorkrecordMachineryExists) {
    return 'Удалить орудие нельзя, поскольку оно указано в работах';
  }
  return 'Ошибка удаления орудия';
};
